<template>
  <br-component component="blog">
    <template #default="{ page, component }">
      <BlogTopicsHeader :page="page" :component="component" />
    </template>
  </br-component>

  <div
    data-test-id="blogHomeGrid"
    class="grid sm:grid-cols-12 gap-lg sm:gap-sm max-w-[1540px] mx-auto sm:p-xs md:p-md text-charcoal-default"
  >
    <div class="sm:col-span-7" data-test-id="blogHomeHeroArticle">
      <br-component component="intro/left" />
    </div>
    <div class="sm:col-span-5 px-xs sm:px-2xs" data-test-id="blogHomeFeatureArticles">
      <Heading size="sm" class="mb-md"> Featured Articles </Heading>
      <br-component component="intro/right" />
    </div>
  </div>

  <br-component component="main" data-test-id="blogHomeMain" />
</template>
